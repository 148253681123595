<template>
  <div class="site">
    <van-nav-bar fixed title="厚仁留学全服务" left-text="返回" left-arrow @click-left="onClickLeft"></van-nav-bar>
    <div class="cat-list">
      <div v-for="catList in list" :key="catList.title" class="cat-item">
        <h3>{{ catList.title }}</h3>
        <div class="list">
          <div class="list-item" v-for="item in catList.con" :key="item.name">
            <SiteItem :item="item"></SiteItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteItem from '../home/components/SiteItem';

import { backInitMixin } from '@/utils/mixin';
import _ from 'lodash';
export default {
  components: { SiteItem },
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      site: []
    };
  },
  computed: {
    list() {
      const myList = [];
      const tt = _.groupBy(this.site, 'category');
      Object.keys(tt).map(item => {
        myList.push({
          title: item,
          con: tt[item]
        });
      });

      return myList;
    }
  },
  created() {
    this.getSite();
  },
  mounted() {},
  watch: {},
  methods: {
    getSite() {
      this.loading = true;
      this.$api['home/site']()
        .then(res => {
          console.log(res, 'site');
          if (res.message) {
            this.site = res.message;
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>

<style scoped lang="less">
.site {
  padding: 46px 16px 0;
  .cat-list {
    padding-top: 20px;
    .cat-item {
      h3 {
        text-align: left;
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        color: #333;
        margin-bottom: 20px;
      }
      .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .list-item {
          margin-right: 2.5%;
          margin-bottom: 20px;
          width: 18%;

          &:nth-child(5n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
